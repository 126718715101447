@font-face {
  font-family: 'KimjungchulGothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulGothic-Bold.woff2')
    format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'KimjungchulGothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulGothic-Bold.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'KimjungchulGothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulGothic-Bold.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
}
